window.isChildrenInDiv = (event) => {
  var item_related = $(event.related);
  var type = item_related.attr("type-sortable");

  return type != "add_form";
};

window.ajaxUpdatePosition = (type, id, listId, team_id = null, controller_name = null) => {
  var param;
  if (!!team_id) {
    param = "teamid_" + team_id + "-" + type + "-" + listId.join("_");
  } else {
    param = type + "-" + listId.join("_");
  }
  var init_value = $("#new_position_arr").val();
  var new_value = !!!init_value ? param : init_value + "," + param;
  var init_controller = $("#controller_input").val();
  if (controller_name == "teams" || init_controller.includes("teams")) {
    var new_controller = !!!init_controller
      ? controller_name
      : init_controller + "," + controller_name;
  } else {
    var new_controller = controller_name;
  }

  $("#new_position_arr").val(new_value);
  $("#controller_input").val(new_controller);
};

window.saveNewOrder = (e) => {
  let new_position_arr, controller_input, param_path;
  new_position_arr = $("#new_position_arr").val();
  controller_input = $("#controller_input").val();
  param_path = get_page_need_updated();

  if (!!new_position_arr) {
    $.ajax({
      url: `/pages/accounts/reorder_okr`,
      type: "PUT",
      dataType: "script",
      data: {
        new_position_arr: new_position_arr,
        controller_input: controller_input,
        detail_page_id: param_path[2],
      },
      success: function (data) {
        $("#modal_loading").modal("hide");
        $("#new_position_arr").val("");
      },
      beforeSend: function () {
        $("#modal_loading").modal("show");
      },
    });
  }

  // Support Communication with React Toggle Order
  window.dispatchEvent(new Event("isOrderChange"));
  $(".banner-reorder").removeClass("d-flex").addClass("d-none");
  $(".my-handle-objective").addClass("d-none");
  $(".my-handle-kr").addClass("d-none");
  $(".reorder-btn").removeClass("d-none").addClass("d-flex");
  $(".reorder-btn").attr("on-off", false);
  $(".okr-3dot-width").attr("style", "visibility: visible");
  $(".expand-teamokrs-position").attr("style", "z-index: 5;");
  if ($(".expand-all-icon").length == 1) {
    $(".expand-all-icon").attr("style", "visibility: visible");
  } else if ($(".header-okrs").length == 1) {
    $(".header-okrs").children().first().attr("style", "visibility: visible");
  }
  $(".btn-add-kr-chil").css("pointer-events", "unset");
  $(".btn-add-kr-chil").parent().attr("data-original-title", "");
  $(".plus-add-kr-icon").parent().css("pointer-events", "unset");
  $(".add-ob-annualy").css("pointer-events", "unset");
  $(".add-ob-annualy").parent().attr("data-original-title", "");

  $(".disabled-reordering").attr("disabled", false);
  flash_msg_js("Positions saved!", null);
  $(".annualy_or_normaly").css("pointer-events", "unset");
  $(".filter-team-okrs").css("pointer-events", "unset");
  $("#controller_input").val("");
};

window.turnOffOrder = async (e) => {
  $(".banner-reorder").removeClass("d-flex").addClass("d-none");
  $(".my-handle-objective").addClass("d-none");
  $(".my-handle-kr").addClass("d-none");
  $(".reorder-btn").removeClass("d-none").addClass("d-flex");
  $(".reorder-btn").attr("on-off", false);
  $(".okr-3dot-width").attr("style", "visibility: visible");
  $(".expand-teamokrs-position").attr("style", "z-index: 5;");
  if ($(".expand-all-icon").length == 1) {
    $(".expand-all-icon").attr("style", "visibility: visible");
  } else if ($(".header-okrs").length == 1) {
    $(".header-okrs").children().first().attr("style", "visibility: visible");
  }
  localStorage.setItem("load_msg", "Reordering cancelled");
  window.location.reload();
};

window.runReorderBtn = () => {
  $(".reorder-btn").attr("style", "pointer-events: all;");
};

window.flash_msg_js = (msg, color) => {
  var alertTxt = `<div class="flash_messages_wrapper">
                    <div class="position-relative alert m-auto border-0 text-center w-100 color-white bg-green-100" style="z-index: 1;" id="alert_dismissible">
                      <span id="flash_success" class="position-relative font-size-16px">
                        ${msg}
                      </span>
                    </div>
                  </div>`;
  $(".flash_messages_wrapper").remove();
  $(".container").append(alertTxt);
  $("#alert_dismissible").slideDown(500);
  setTimeout(function () {
    $("#alert_dismissible").slideUp(500);
  }, 3000);
};

window.showAlertReorder = (nameType) => {
  if (nameType == "objectives" || nameType == "teams")
    $("#alertReorderOnlyOkrDetail").modal("show");
  else $("#alertReorder").modal("show");
};

window.reorder_row = (nameType) => {
  let ele_icon, iconChange, ele, param_path;
  param_path = get_page_need_updated();
  ele_icon = $(".reorder-btn");
  if (ele_icon.attr("on-off") == "false") {
    $(".banner-reorder").removeClass("d-none").addClass("d-flex");

    if (nameType == "objectives_show" || nameType == "teams") {
      $(".my-handle-objective, .my-handle-kr").removeClass("d-none");
    } else {
      $(".my-handle-objective").removeClass("d-none");
    }

    $(".reorder-btn").removeClass("d-flex").addClass("d-none");
    $(".okr-3dot-width").attr("style", "visibility: hidden");
    ele_icon.attr("on-off", true);
    $(".expand-teamokrs-position").attr("style", "z-index: 0;");

    if ($(".expand-all-icon").length == 1) {
      if ($(".expand-all-icon").attr("value") == "collapse") {
        $(".expand-all-icon").trigger("click");
      } else {
        iconChange = $("i.fa-chevron-down");
        $("li.parent_li").find(" > ul > li").hide("fast");
        $(".item[okr-title='Collapse this branch']").removeClass("bg-white-200");
        $(iconChange).addClass("fa-chevron-right").removeClass("fa-chevron-down");
        $(".expand-all-icon").attr("value", "expand");
        $(".expand-all-icon").children(".collapse-expand-title").text("Expand All");
        $(".expand-all-icon")
          .children("#icon_title_down")
          .removeClass("d-block")
          .addClass("d-none");
        $(".expand-all-icon").children("#icon_title_up").addClass("d-block").removeClass("d-none");
      }
      if (nameType != "objectives_show" && nameType != "teams" && param_path[0] != "teams") {
        $(".expand-all-icon").attr("style", "visibility: hidden");
      }
    } else if ($(".header-okrs").length == 1) {
      ele = $(".header-okrs").children().first();
      if (ele.attr("value") == "collapse") {
        ele.trigger("click");
      } else {
        iconChange = $("i.fa-chevron-down");
        $("li.parent_li").find(" > ul > li").hide("fast");
        $(".item[okr-title='Collapse this branch']").removeClass("bg-white-200");
        $(iconChange).addClass("fa-chevron-right").removeClass("fa-chevron-down");
        ele.attr("value", "expand");
        ele.children(".collapse-expand-title").text("Expand All");
        ele.children("#icon_title_down").removeClass("d-block").addClass("d-none");
        ele.children("#icon_title_up").addClass("d-block").removeClass("d-none");
      }
      if (nameType != "objectives_show" && nameType != "teams" && param_path[0] != "teams") {
        ele.attr("style", "visibility: hidden");
      }
    }
    $(".btn-add-kr-chil").css("pointer-events", "none");
    $(".btn-add-kr-chil").parent().attr("data-original-title", "Can't add KR when reordering");
    $(".plus-add-kr-icon").parent().css("pointer-events", "none");
    $(".add-ob-annualy").css("pointer-events", "none");
    $(".add-ob-annualy")
      .parent()
      .attr("data-original-title", "Can't link Quarterly Objective when reordering");
    // Disable update KR when reordering
    $("input[type=range]").not("[disabled]").addClass("disabled-reordering").attr("disabled", true);
    // Disable annualy_normaly_choice
    $(".annualy_or_normaly").css("pointer-events", "none");
    $(".filter-team-okrs").css("pointer-events", "none");
  } else {
    // Nothing
  }
};
