window.expandonelevel = (e) => {
  let allOKRs = e.parent().parent().parent();
  if (e.attr("value") == "expand") {
    e.attr("value", "collapse");
    e.find(".expand-all-div").removeClass("d-flex").addClass("d-none");
    e.find(".collapse-all-div").removeClass("d-none").addClass("d-flex");
    if ($(".arrow-show-children").length > 0) {
      $("i.fa-chevron-right").not(".d-none").parents(".arrow-show-children").trigger("click");
    } else if ($(".tree li:has(ul)").length > 0) {
      [...$(".tree li:has(ul)")].forEach((element) => {
        $(element).find("i.fa-chevron-right").parent(".arrow-show-children").trigger("click");
      });
    }
    allOKRs.find(`img.arrow-up-first`).removeClass("d-block").addClass("d-none");
    allOKRs.find(`img.arrow-down-first`).addClass("d-block").removeClass("d-none");
    allOKRs.find(`img.arrow-up-first-child`).removeClass("d-block").addClass("d-none");
    allOKRs.find(`img.arrow-down-first-child`).addClass("d-block").removeClass("d-none");
  } else if (e.attr("value") == "collapse") {
    e.attr("value", "expand");
    e.find(".expand-all-div").removeClass("d-none").addClass("d-flex");
    e.find(".collapse-all-div").removeClass("d-flex").addClass("d-none");
    if ($(".tree li:has(ul)").length > 0) {
      $("i.fa-chevron-down").trigger("click");
    } else if ($(".arrow-show-children").length > 0) {
      $(".arrow-show-children").trigger("click");
    }
    allOKRs.find(`img.arrow-up-first`).addClass("d-block").removeClass("d-none");
    allOKRs.find(`img.arrow-down-first`).removeClass("d-block").addClass("d-none");
    allOKRs.find(`img.arrow-up-first-child`).addClass("d-block").removeClass("d-none");
    allOKRs.find(`img.arrow-down-first-child`).removeClass("d-block").addClass("d-none");
  }
};

window.expandAllLevel = (e) => {
  e.css("pointer-events", "none");
  let allOKRs = e.parent().parent().parent();
  let paramsUrl = get_page_need_updated();
  if (e.attr("value") == "expand") {
    e.attr("value", "collapse");
    e.find(".expand-all-div").removeClass("d-flex").addClass("d-none");
    e.find(".collapse-all-div").removeClass("d-none").addClass("d-flex");
    e.children(".collapse-expand-title").text("Collapse All");
    if ($(".arrow-show-children").length > 0) {
      if (["companies", "my_okrs", "departments", "teams", "user_okrs"].includes(paramsUrl[0])) {
        $("i.fa-chevron-right")
          .not(".d-none")
          .parents(".arrow-show-children")
          .attr("expand-attr", "all");
      }
      $("i.fa-chevron-right").not(".d-none").parents(".arrow-show-children").trigger("click");
    } else if ($(".tree li:has(ul)").length > 0) {
      [...$(".tree li:has(ul)")].forEach((element) => {
        if (["companies", "my_okrs", "departments", "teams", "user_okrs"].includes(paramsUrl[0])) {
          $(element)
            .find("i.fa-chevron-right")
            .parent(".arrow-show-children")
            .attr("expand-attr", "all");
        }
        $(element).find("i.fa-chevron-right").parent(".arrow-show-children").trigger("click");
      });
    } else {
      if (["companies", "my_okrs", "departments", "teams", "user_okrs"].includes(paramsUrl[0])) {
        $("i.fa-chevron-right")
          .not(".d-none")
          .parents(".arrow-show-children")
          .attr("expand-attr", "all");
      }
      $("i.fa-chevron-right").not(".d-none").parents(".arrow-show-children").trigger("click");
    }
    allOKRs.find(`img.arrow-up-first`).removeClass("d-block").addClass("d-none");
    allOKRs.find(`img.arrow-down-first`).addClass("d-block").removeClass("d-none");
    allOKRs.find(`img.arrow-up-first-child`).removeClass("d-block").addClass("d-none");
    allOKRs.find(`img.arrow-down-first-child`).addClass("d-block").removeClass("d-none");
  } else if (e.attr("value") == "collapse") {
    e.attr("value", "expand");
    e.find(".expand-all-div").removeClass("d-none").addClass("d-flex");
    e.find(".collapse-all-div").removeClass("d-flex").addClass("d-none");
    e.children(".collapse-expand-title").text("Expand All");
    if ($(".tree li:has(ul)").length > 0) {
      $("i.fa-chevron-down").trigger("click");
    } else if ($(".arrow-show-children").length > 0) {
      $(".arrow-show-children").attr("expand-attr", "");
      $(".arrow-show-children").trigger("click");
    }
    allOKRs.find(`img.arrow-up-first`).addClass("d-block").removeClass("d-none");
    allOKRs.find(`img.arrow-down-first`).removeClass("d-block").addClass("d-none");
    allOKRs.find(`img.arrow-up-first-child`).addClass("d-block").removeClass("d-none");
    allOKRs.find(`img.arrow-down-first-child`).removeClass("d-block").addClass("d-none");
  }
  setTimeout(function () {
    e.css("pointer-events", "auto");
  }, 1000);
};

window.change_status_icon_collapse_expand = (allOKRs, status) => {
  if (status) {
    allOKRs.find(`img.arrow-up`).removeClass("d-block").addClass("d-none");
    allOKRs.find(`img.arrow-down`).addClass("d-block").removeClass("d-none");
  } else {
    allOKRs.find(`img.arrow-up`).addClass("d-block").removeClass("d-none");
    allOKRs.find(`img.arrow-down`).removeClass("d-block").addClass("d-none");
  }
};

window.showHideKeyResults = (e) => {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    // true for mobile device
    return true;
  }
  let iconChange, cluster, allLiInCluster;
  cluster = e.closest(".title-hierarchy-line");
  allLiInCluster = cluster.find("li.parent_li").find(" > ul > li");
  if (e.attr("value") == "up") {
    iconChange = cluster.find("li.parent-first").find("i.fa-chevron-right.first-view");
    if (cluster.find(".arrow-show-children").length > 0) {
      iconChange.not(".d-none").parents(".arrow-show-children").trigger("click");
    } else if (allLiInCluster.length > 0) {
      allLiInCluster.show("fast");
    }
    cluster.find(".item[okr-title='Collapse this branch']").addClass("bg-white-200");
    $(iconChange).addClass("fa-chevron-down").removeClass("fa-chevron-right");
    e.attr("value", "down");
    e.children(".collapse-expand-title").text("Collapse All");
    e.children("#icon_title_up").removeClass("d-block").addClass("d-none");
    e.children("#icon_title_down").addClass("d-block").removeClass("d-none");
    cluster.find(`img.arrow-up-first`).removeClass("d-block").addClass("d-none");
    cluster.find(`img.arrow-down-first`).addClass("d-block").removeClass("d-none");
    cluster.find(`img.arrow-up-first-child`).removeClass("d-block").addClass("d-none");
    cluster.find(`img.arrow-down-first-child`).addClass("d-block").removeClass("d-none");
  } else {
    iconChange = cluster.find("i.fa-chevron-down");
    allLiInCluster.hide("fast");
    cluster.find(".item[okr-title='Collapse this branch']").removeClass("bg-white-200");
    $(iconChange).addClass("fa-chevron-right").removeClass("fa-chevron-down");
    e.attr("value", "up");
    e.children(".collapse-expand-title").text("Expand All");
    e.children("#icon_title_down").removeClass("d-block").addClass("d-none");
    e.children("#icon_title_up").addClass("d-block").removeClass("d-none");
    cluster.find(`img.arrow-up-first`).addClass("d-block").removeClass("d-none");
    cluster.find(`img.arrow-down-first`).removeClass("d-block").addClass("d-none");
    cluster.find(`img.arrow-up-first-child`).addClass("d-block").removeClass("d-none");
    cluster.find(`img.arrow-down-first-child`).removeClass("d-block").addClass("d-none");
  }
};

window.underLineText = (e) => {
  $(e).find(".owner-text-show").css("text-decoration", "underline");
};

window.normalLineText = (e) => {
  $(e).find(".owner-text-show").css("text-decoration", "unset");
};

window.showHideDepartmentCluster = (e) => {
  let cluster, allLiInCluster, id, deptIds;
  id = e.closest(".dept-cluster").attr("dept-id");
  cluster = e.closest(".title-hierarchy-line");
  allLiInCluster = cluster.find("li.parent_li").find(" > ul > li");
  deptIds = [];
  if ($(".dept-cluster").length > 0) {
    [...$(".dept-cluster")].forEach((e) => {
      deptIds.push($(e).attr("dept-id"));
    });
  }
  if (e.attr("value") == "up") {
    $(`.dept-cluster-${id}`).hide("fast");
    e.attr("value", "down");
    e.children(".collapse-expand-title").text("Collapse All");
    e.children("#icon_title_up").removeClass("d-block").addClass("d-none");
    e.children("#icon_title_down").addClass("d-block").removeClass("d-none");
    cluster.find(`img.arrow-up-first`).removeClass("d-block").addClass("d-none");
    cluster.find(`img.arrow-down-first`).addClass("d-block").removeClass("d-none");
    cluster.find(`img.arrow-up-first-child`).removeClass("d-block").addClass("d-none");
    cluster.find(`img.arrow-down-first-child`).addClass("d-block").removeClass("d-none");
    if (deptIds.indexOf(id) == 0)
      $(".expand-teamokrs-position, .collapse-teamokrs-position")
        .removeClass("d-flex")
        .addClass("d-none");
  } else {
    $(`.dept-cluster-${id}`).show("fast");
    e.attr("value", "up");
    e.children(".collapse-expand-title").text("Expand All");
    e.children("#icon_title_down").removeClass("d-block").addClass("d-none");
    e.children("#icon_title_up").addClass("d-block").removeClass("d-none");
    cluster.find(`img.arrow-up-first`).addClass("d-block").removeClass("d-none");
    cluster.find(`img.arrow-down-first`).removeClass("d-block").addClass("d-none");
    cluster.find(`img.arrow-up-first-child`).addClass("d-block").removeClass("d-none");
    cluster.find(`img.arrow-down-first-child`).removeClass("d-block").addClass("d-none");
    if (deptIds.indexOf(id) == 0)
      $(".expand-teamokrs-position, .collapse-teamokrs-position")
        .removeClass("d-none")
        .addClass("d-flex");

    if (cluster.find(".team-cluster").length > 0) {
      [...cluster.find(".team-cluster")].forEach((e) => {
        if ($(e).find("i.fa-chevron-right.first-view").not(".d-none").length == 0) {
          $(e).find(`img.arrow-up-first`).removeClass("d-block").addClass("d-none");
          $(e).find(`img.arrow-down-first`).addClass("d-block").removeClass("d-none");
          $(e).find(`img.arrow-up-first-child`).removeClass("d-block").addClass("d-none");
          $(e).find(`img.arrow-down-first-child`).addClass("d-block").removeClass("d-none");
        }
      });
    }
  }
};
