window.flash_msg_js_custom = (msg, component_id, color, parent, index) => {
  var alertTxt = `
    <div class="flash_messages_wrapper" style="z-index: ${index == null ? "" : index}">
      <div class="position-relative alert m-auto border-0 text-center w-100 color-white ${color}" style="z-index: 1;" id="alert_dismissible">
        <span id="flash_success" class="position-relative font-size-16px">
          ${msg}
        </span>
      </div>
    </div>
  `;
  $(".flash_messages_wrapper").remove();
  $(`${parent == null ? "body" : parent} ${component_id}`).append(alertTxt);

  $("#alert_dismissible").delay(500).slideDown(500).delay(3000).slideUp(500);
};

window.flash_error_custom = (msg, component_id, fields) => {
  $.each(fields, function (index, value) {
    $(`${component_id}_${value}`).css({
      "border-color": "#EE3158",
      "border-width": "2px",
    });
  });
  window.flash_msg_js_custom(msg, component_id, "bg-red");
};
