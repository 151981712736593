window.showChildrenOkr = (e) => {
  e.css("pointer-events", "none");
  let id, condition, params, reorderSign;
  id = e.attr("okrid");
  condition = $(`.okr-item-${id}`).parent().children().length == 1;
  params = get_page_need_updated();
  reorderSign =
    !$(".banner-reorder").hasClass("d-none") &&
    ["teams", "departments", "objectives"].includes(params[0]);
  $.ajax({
    type: "GET",
    url: `/v3/objectives/${id}/show_children`,
    data: {
      condition: condition,
      type_page: params[0],
      reorder_sign: reorderSign,
    },
    success: function (data) {
      e.css("pointer-events", "auto");
    },
  });
};
