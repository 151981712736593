window.openLinkToAnnualModal = () => {
  $("#modalLinkToAnnual").modal("show");
  $("#inputLinkToAnnual").val("");
  $.ajax({
    url: "/v3/objectives/link_quarterly_ob",
    type: "GET",
    data: {
      level_type: $("#type_objective").val(),
      year: $("#objective_period_year").val(),
    },
  });
};

window.searchLinkedOb = debounce((e) => {
  $.ajax({
    url: "/v3/objectives/link_quarterly_ob",
    type: "GET",
    data: {
      search: e.val(),
      level_type: $("#type_objective").val(),
      year: $("#objective_period_year").val(),
    },
  });
}, 500);

window.searchAddToAnnual = debounce((e) => {
  let levelOb, yearOb;
  levelOb = $("#titleAddToAnnual").attr("level_ob");
  yearOb = $("#titleAddToAnnual").attr("year_ob");
  $.ajax({
    url: "/v3/objectives/quarterly_ob_list",
    type: "GET",
    data: {
      search: e.val(),
      level_type: levelOb,
      year: yearOb,
    },
  });
}, 500);

window.openAddObAnnual = (e) => {
  $("#modalAddToAnnual").modal("show");
  $("#inputAddToAnnual").val("");
  $("#temporary_linked_ob_ids").val("");
  $(".btnAddObsAnnual").attr("annualy_ob", e.attr("annualy_ob"));
  $("#titleAddToAnnual").attr({
    annualy_ob: e.attr("annualy_ob"),
    level_ob: e.attr("level_ob"),
    year_ob: e.attr("year_ob"),
  });
  $.ajax({
    url: "/v3/objectives/quarterly_ob_list",
    type: "GET",
    data: {
      level_type: e.attr("level_ob"),
      year: e.attr("year_ob"),
    },
  });
};

window.cancelLinkedObs = () => {
  let arrA = $("a[onclick='unlinkedObToAnnual($(this))']");
  for (let i of arrA) {
    $(arrA[i]).text("Link");
    $(arrA[i]).attr("onclick", "linkedObToAnnual($(this))");
  }
  let oldData = [...$(".nested-fields")]
    .map((e) => $(e).attr("data-index"))
    .map((e) => e.split("-")[1]);
  $("#linked_obs_annual").val(oldData.toString());
  $("#modalLinkToAnnual").modal("hide");
};

window.cancelAddLinkedObs = () => {
  $("#temporary_linked_ob_ids").val("");
  $("#modalAddToAnnual").modal("hide");
};

window.renderLinkedObs = (e) => {
  $("#modalLinkToAnnual").modal("hide");
  let new_ids = $("#linked_obs_annual").val();
  $.ajax({
    url: "/v3/objectives/render_ob",
    type: "GET",
    data: {
      list_ids: new_ids.split(","),
    },
  });
};

window.addLinkedObsToAnnual = (e) => {
  let new_ids, annual_id, pageParams;
  $("#modalAddToAnnual").modal("hide");
  new_ids = $("#temporary_linked_ob_ids").val();
  annual_id = e.attr("annualy_ob");
  pageParams = get_page_need_updated();
  $.ajax({
    url: `/v3/objectives/${annual_id}/add_quarterly_obs`,
    type: "PUT",
    data: {
      list_ids: new_ids,
      type_page: pageParams[0],
      quarter: urlParam("quarter"),
      year: urlParam("year"),
      user_id: pageParams[1],
    },
    beforeSend: function () {
      loadFilterOKR();
    },
    success: function () {
      $("#temporary_linked_ob_ids").val("");
    },
  });
};

window.loadFilterOKR = () => {
  $("#modal_loading").modal("show");
  $("#modal_loading .text-loading").html("Loading OKRs...");
  setTimeout(function () {
    $("#modal_loading .text-loading").html("Almost there...");
  }, 3000);
  setTimeout(function () {
    $("#modal_loading .text-loading").html("Hang tight...");
  }, 6000);
};

window.annualyOrNormaly = (e) => {
  let pageParams, type_okrs, oldUrl, newUrl;
  oldUrl = window.location.pathname + window.location.search;
  pageParams = get_page_need_updated();

  console.log(pageParams);

  if (oldUrl.includes("annualy_normaly_choice")) {
    if (urlParam("annualy_normaly_choice") == "0") {
      newUrl = oldUrl.replace("annualy_normaly_choice=0", "annualy_normaly_choice=1");
    } else {
      newUrl = oldUrl.replace("annualy_normaly_choice=1", "annualy_normaly_choice=0");
    }
  } else {
    newUrl = oldUrl + `&annualy_normaly_choice=${e.val() || e.attr("value")}`;
  }
  if (pageParams[0] == "team_okrs") {
    type_okrs = $('.filter-team-okrs input[name="check"]:checked').val();
    $.ajax({
      url: `/pages/team_okrs/filter_team_okr`,
      type: "GET",
      beforeSend: function () {
        xstart();
        loadFilterOKR();
      },
      data: {
        annualy_normaly_choice: e.val() || e.attr("value"),
        type: type_okrs,
        quarter: urlParam("quarter"),
        year: urlParam("year"),
      },
      success: function (data) {
        $("#modal_loading").modal("hide");
        window.history.pushState(null, "", newUrl);
        xend();
      },
    });
  } else if (["teams", "departments"].includes(pageParams[0])) {
    $.ajax({
      url: `/v3/${pageParams[0]}/filter_${pageParams[0]}_obs`,
      type: "GET",
      beforeSend: function () {
        loadFilterOKR();
      },
      data: {
        annualy_normaly_choice: e.val() || e.attr("value"),
        type_page: pageParams[0],
        id: pageParams[4],
        quarter: urlParam("quarter"),
        year: urlParam("year"),
      },
      success: function (data) {
        window.history.pushState(null, "", newUrl);
      },
    });
  } else {
    $.ajax({
      url: `/v3/${pageParams[0]}/filter_${pageParams[0]}_obs`,
      type: "GET",
      beforeSend: function () {
        loadFilterOKR();
      },
      data: {
        annualy_normaly_choice: e.val() || e.attr("value"),
        quarter: urlParam("quarter"),
        year: urlParam("year"),
        id: pageParams[1],
      },
      success: function (data) {
        if (pageParams[0] == "my_okrs") {
          callbackJsObjective();
        }
        window.history.pushState(null, "", newUrl);
      },
    });
  }
};

window.load_okrs = () => {
  $(".tree-objectives ul").each(function (index) {
    let id = $(this).data("id");
    return new Promise(function (resolve, reject) {
      load_data_an_obj(id);
    });
  });
};

window.saveNormalKrs = () => {
  let config, id_attr, childrens_attributes, childrens, id, key, value, temp;
  config = {};
  id_attr = [];
  childrens_attributes = {};
  childrens = [];
  $("form.new_objective")
    .serializeArray()
    .map((item) => {
      if (config[item.name]) {
        if (typeof config[item.name] === "string") {
          config[item.name] = [config[item.name]];
        }
        config[item.name].push(item.value);
      } else {
        if (item.name.includes("objective[childrens_attributes]")) {
          id = item.name.match(/(\d+)/)[0];
          key = item.name.split("[")[3].replace("]", "");
          value = item.value;
          if (id_attr.includes(id)) {
            // Check any form KR has existed
            childrens_attributes[id][key] = value;
          } else {
            // No form KR has existed
            id_attr.push(id);
            temp = {};
            temp[key] = value;
            childrens_attributes[id] = temp;
          }
        } else if (item.name == "authenticity_token") {
          // Nothing
        } else if (item.name.includes("objective[")) {
          key = item.name.split("[")[1].replace("]", "");
          config[key] = item.value;
        } else {
          config[item.name] = item.value;
        }
      }
    });
  Object.keys(childrens_attributes).map(function (keyIndex) {
    return childrens.push(JSON.stringify(childrens_attributes[keyIndex]));
  });
  config["childrens_attributes"] = JSON.stringify(childrens);
  localStorage.setItem("normal_krs", JSON.stringify(config));
  return JSON.stringify(config);
};

window.decodeNormalKrs = () => {
  let objective = localStorage.getItem("normal_krs");
  if (objective != null) {
    let childrens_attributes = {};
    objective = JSON.parse(objective);
    let childrens = JSON.parse(objective["childrens_attributes"]);
    childrens.forEach((e, index) => {
      childrens_attributes[index] = JSON.parse(e);
    });
    objective["childrens_attributes"] = childrens_attributes;
    console.log(objective);
  }
  return objective;
};

window.openAlertDeleteAnnual = (e) => {
  $("#alertDeleteAnnualyOb").modal("show");
  $("#annual-delete-btn").attr("obj_id", e.attr("obj_id"));
};

window.deleteAnnulyOb = (e) => {
  let params, idOb, type_teams;
  params = get_page_need_updated();
  idOb = e.attr("obj_id");
  // check my teams or all teams in team okrs
  if ($('.filter-team-okrs input[name="check"]:checked').length > 0) {
    type_teams =
      $('.filter-team-okrs input[name="check"]:checked').val() == "0" ? "my_teams" : "all_teams";
  }
  $.ajax({
    url: `/v3/objectives/${idOb}/delete_annualy`,
    type: "DELETE",
    beforeSend: function () {
      $("#alertDeleteAnnualyOb").modal("hide");
      loadFilterOKR();
    },
    data: {
      controller_name: params[0],
      user_id: params[1],
      obj_id: params[2],
      type_team_okrs: params[3],
      year: urlParam("year"),
      quarter: urlParam("quarter"),
      type_teams: type_teams,
      detail_page_id: params[4],
      annualy_normaly_choice: urlParam("annualy_normaly_choice"),
    },
    dataType: "script",
  });
};

window.linkedObToAnnual = (e) => {
  let old_ids, new_ids, value;
  value = e.attr("linked_ob");
  old_ids = $("#linked_obs_annual").val();

  if (old_ids == "") {
    new_ids = `${value}`;
  } else {
    new_ids = `${old_ids},${value}`;
  }
  $("#linked_obs_annual").val(new_ids);
  e.text("Unlink");
  e.attr("onclick", "unlinkedObToAnnual($(this))");
};

window.unlinkedObToAnnual = (e) => {
  let arr_old_ids, arr_new_ids, value;
  value = e.attr("linked_ob");
  arr_old_ids = $("#linked_obs_annual").val().split(",");
  arr_new_ids = arr_old_ids.filter((id) => parseInt(id) != value);
  $("#linked_obs_annual").val(arr_new_ids.toString());
  e.text("Link");
  e.attr("onclick", "linkedObToAnnual($(this))");
};

window.check_exist_form_add_kr = () => {
  $(".card-add-kr")
    .find("h3")
    .removeClass("mb-12dot5")
    .addClass("mb-3")
    .css("visibility", "visible");
  $(".card-add-kr").find(".card-body").removeClass("bg-white-200 border-radius-10px");
  $(".card-add-kr").find(".icon-add-kr").removeClass("text-center").addClass("text-left");
  $(".card-add-kr").find(".icon-add-kr #icon-add-kr").css("display", "none");
  $(".card-add-kr").find(".btn-plus-text").removeClass("d-none");
  $(".card-add-kr").find(".add-kr-label").css({
    color: "gray",
    "font-weight": "normal",
  });
  $(".card-add-kr hr").css("display", "block");
  $(".card-add-kr .nested-fields").last().find("hr").css("display", "none");
  $(".links").removeClass("text-center");
  $(".objective_childrens_name input").css("border", "1px solid #d5d5d5");
  $(".titlte-kr-creation").addClass("d-none");
};

window.changeObjectiveCreateokrs = (e) => {
  $.ajax({
    type: "GET",
    url: `/pages/objectives/linked_objective_createokr`,
    data: {
      present_quarter: $("#objective_period_quarter").val(),
      present_year: $("#objective_period_year").val(),
      type_objective: $("#type_objective").val(),
    },
    success: function (data) {
      $("#modalLinkedObjective").text("Change Linked Objective");
      $("#inputSearchLinkObj").val("");
    },
  });
};

window.showUnLinkingModalCreateokrs = (e) => {
  $("#modalUnlinkCreateokr").modal();
  $("#cancelUnlinkBtn").attr("back-condition", "true");
  $(".unlinking-btn").attr("obj_id", e.attr("obj_id"));
};

window.mobileAddKr = (e) => {
  $("#mobileKrFormModal .icon-add-kr").trigger("click");
  $(".mobile-remove-kr-form").removeClass("d-none").addClass("d-flex");
};

window.hideMobileTeamOwnerModal = () => {
  $("#mobileTeamOwnerModal").modal("hide");
};
