window.jQuery = $;
window.$ = $;
window.Highcharts = Highcharts;
window.Sortable = Sortable;
window.Tribute = Tribute;

require("@rails/ujs").start();
require("@rails/activestorage").start();
require("channels");

require("bootstrap");

import "./pages/plugin/jquery/jquery.js";
import "./pages/plugin/jquery-easing/jquery.easing.js";
require("chart.js");
import "cocoon-js";
import "./pages/plugin/select2.js";
import "./pages/plugin/selectize.js";
import "./pages/goal.js";
import "./pages/plugin/bootstrap-datepicker.min.js";
import flatpickr from "flatpickr";
import * as ChartModuleMore from "highcharts/highcharts-more.js";
import HCSoldGauge from "highcharts/modules/solid-gauge";
import HCNodataToDisplay from "highcharts/modules/no-data-to-display";
import Highcharts from "highcharts";
import Sortable from "sortablejs";
import Tribute from "tributejs";

ChartModuleMore(Highcharts);
HCSoldGauge(Highcharts);
HCNodataToDisplay(Highcharts);

export * from "./pages/objectives.js";
export * from "./pages/reorder_sortable.js";
export * from "./pages/show_hide_okr.js";
export * from "./pages/searching_team.js";
export * from "./pages/change_period.js";
export * from "./pages/history.js";

export * from "./v3/objectives.js";
export * from "./v3/children_okrs.js";

export * from "./stripe/payment.js";
export * from "./custom.js";

document.addEventListener("turbolinks:load", () => {
  flatpickr("[data-behavior='flatpickr']", {
    altInput: true,
    altFormat: "F j, Y",
    dateFormat: "Y-m-d",
  });
});

$(document).ready(function () {
  var collapse_ids = JSON.parse(localStorage.getItem("collapse_okr") || "[]");
  $.each(collapse_ids, function (index, value) {
    $("[data-id=okr-" + value + "]").click();
    if ($("[data-id=okr-" + value + "]").get(0) != undefined)
      $("[data-id=okr-" + value + "]")
        .get(0)
        .scrollIntoView();
  });
  // save localStorage create objective form
  var objective = localStorage.getItem("objective");
  var flash_message = localStorage.getItem("flash_message");
  var linked_annual = localStorage.getItem("linked_annual");
  localStorage.clear();
  if (objective != undefined) {
    localStorage.setItem("objective", objective);
  }
  if (linked_annual != undefined) {
    localStorage.setItem("linked_annual", linked_annual);
  }
  if (!!flash_message) {
    afterUpdateBigProgressBar(flash_message);
  }
});

// Add stimulus
import "../controllers";

import consumer from "../channels/consumer.js";
window.consumer = consumer;

window.xstart = () => {
  window.tmp_start = new Date().getTime();
  console.log("Request called at: ", window.tmp_start);
};

window.xend = () => {
  window.tmp_end = new Date().getTime();
  console.log("Response received at: ", window.tmp_end);
  window.tmp_cost = window.tmp_end - window.tmp_start;
  console.log("Estimated duration: ", window.tmp_cost);
};
