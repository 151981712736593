window.openChangeTimePeriodModal = () => {
  $("#modalChangeTimePeriod").modal("show");
  $("#modalChangeTimePeriod").find(".error-input-alert").addClass("d-none");
};

window.changeTimePeriod = (e, id, fy) => {
  let date, quarter, year, cur_quarter, cur_year;
  date = new Date();
  quarter = revertQuarterFinancialYear(parseInt($("#change_quarter").val()), fy);
  year = parseInt($("#change_year").val());
  cur_quarter = Math.floor((date.getMonth() + 3) / 3);
  cur_year = date.getFullYear();

  if ((year == cur_year && quarter >= cur_quarter) || year > cur_year) {
    $.ajax({
      type: "PUT",
      url: `/pages/objectives/${id}/change_time_period`,
      data: {
        quarter: parseInt($("#change_quarter").val()),
        year: year,
      },
      success: function (data) {
        $("#modal_loading").modal("hide");
        $("#btn-change-period-time").text("Save");
      },
      beforeSend: function () {
        $("#btn-change-period-time").text("Please wait...");
        $("#modalChangeTimePeriod").modal("hide");
        $("#modal_loading").modal("show");
      },
    });
  } else {
    $("#modalChangeTimePeriod").find(".error-input-alert").removeClass("d-none");
  }
};

window.openDuplicateTimePeriodModal = () => {
  $("#modalDuplicateTimePeriod").modal("show");
  $("#modalDuplicateTimePeriod").find(".error-input-alert").addClass("d-none");
};

window.duplicateTimePeriod = (e, id, fy) => {
  let date, quarter, year, cur_quarter, cur_year;
  date = new Date();
  quarter = revertQuarterFinancialYear(parseInt($("#duplicate_quarter").val()), fy);
  year = parseInt($("#duplicate_year").val());
  cur_quarter = Math.floor((date.getMonth() + 3) / 3);
  cur_year = date.getFullYear();

  if ((year == cur_year && quarter >= cur_quarter) || year > cur_year) {
    $.ajax({
      type: "PUT",
      url: `/pages/objectives/${id}/duplicate_okr`,
      data: {
        quarter: parseInt($("#duplicate_quarter").val()),
        year: year,
      },
      success: function (data) {
        $("#modal_loading").modal("hide");
        $("#btn-duplicate-okr").text("Duplicate");
      },
      beforeSend: function () {
        $("#btn-duplicate-okr").text("Please wait...");
        $("#modalDuplicateTimePeriod").modal("hide");
        $("#modal_loading").modal("show");
      },
    });
  } else {
    $("#modalDuplicateTimePeriod").find(".error-input-alert").removeClass("d-none");
  }
};

window.removeAlertChangeTime = (e) => {
  $(".error-input-alert").addClass("d-none");
};

window.financialYearConvertQuarter = (oldQuarter, financialYear) => {
  let current_quarter, quarter;
  current_quarter = parseInt(oldQuarter);
  switch (financialYear) {
    case 2:
      quarter = current_quarter == 1 ? 4 : current_quarter - 1;
      break;
    case 3:
      quarter = [1, 2].includes(current_quarter) ? current_quarter + 2 : current_quarter - 2;
      break;
    case 4:
      quarter = current_quarter == 4 ? 1 : current_quarter + 1;
      break;
    default:
      quarter = current_quarter;
  }
  return quarter;
};

window.revertQuarterFinancialYear = (viewQuarter, fy) => {
  let quarter, realQuarter;
  quarter = parseInt(viewQuarter);
  switch (parseInt(fy)) {
    case 2:
      realQuarter = quarter == 4 ? 1 : quarter + 1;
      break;

    case 3:
      realQuarter = [1, 2].includes(quarter) ? quarter + 2 : quarter - 2;
      break;

    case 4:
      realQuarter = quarter == 1 ? 4 : quarter - 1;
      break;

    default:
      realQuarter = quarter;
      break;
  }
  return realQuarter;
};

window.financialYearConvertYear = (oldQuarter, oldYear, financialYear) => {
  let current_year, year;
  current_year = parseInt(oldYear);
  year = current_year;
  return year;
};
