window.encode_obj_to_string = (type = null) => {
  let config, id_attr, childrens_attributes, childrens, id, key, value, temp;
  config = {};
  id_attr = [];
  childrens_attributes = {};
  childrens = [];
  $(`form.${type == "edit" ? "edit" : "new"}_objective`)
    .serializeArray()
    .map(function (item) {
      if (config[item.name]) {
        if (typeof config[item.name] === "string") {
          config[item.name] = [config[item.name]];
        }
        config[item.name].push(item.value);
      } else {
        if (item.name.includes("objective[childrens_attributes]")) {
          id = item.name.match(/(\d+)/)[0];
          key = item.name.split("[")[3].replace("]", "");
          value = item.value;
          if (id_attr.includes(id)) {
            // Check any form KR has existed
            childrens_attributes[id][key] = value;
          } else {
            // No form KR has existed
            id_attr.push(id);
            temp = {};
            temp[key] = value;
            childrens_attributes[id] = temp;
          }
        } else if (item.name == "authenticity_token") {
          // Nothing
        } else if (item.name.includes("objective[")) {
          key = item.name.split("[")[1].replace("]", "");
          config[key] = item.value;
        } else {
          config[item.name] = item.value;
        }
      }
    });
  Object.keys(childrens_attributes).map(function (keyIndex) {
    return childrens.push(JSON.stringify(childrens_attributes[keyIndex]));
  });
  config["childrens_attributes"] = JSON.stringify(childrens);
  localStorage.setItem("objective", JSON.stringify(config));
  return JSON.stringify(config);
};

window.get_encode_form_obj = async (type = null) => {
  await encode_obj_to_string(type);
};

window.save_form_data = (type = null) => {
  get_encode_form_obj(type);
};

window.showListTeam = async (e) => {
  let targetSelect = e.closest("#groupSelectTeam").find("#objective_team_id");
  $("#modalTeamOkrSearchTeam").modal("show");
  $("#selectTeamCreateOkr").val("");

  await $.ajax({
    type: "GET",
    url: "/pages/objectives/search_temporary_team",
    data: {
      search: "",
      team_selected: null,
      type_modal: "temporary",
    },
  });

  $(`#modalTeamOkrSearchTeam .list-group-item[teamid="${targetSelect.val()}"]`)
    .find("#icon-checked")
    .removeClass("d-none");
  $("#selectTeamCreateOkr").parent().css("box-shadow", "none");
};

window.openTeamKrInCompany = async (e) => {
  try {
    let timeIdKr, targetSelect, value_row;
    $("#modalTemporarySearchTeam").modal("show");
    timeIdKr = e.parents(".nested-fields").data("index");
    targetSelect = e.val();
    value_row = e.closest("li").attr("type-sortable");
    $("#data-row").val(value_row);
    $("#selectTeamCreateOkr").val("");

    await $.ajax({
      type: "GET",
      url: "/pages/objectives/search_temporary_team",
      data: {
        search: "",
        team_selected: null,
        type_modal: "temporary",
      },
    });

    if (targetSelect != null)
      $("#modalTemporarySearchTeam").find(".icon-checked").not(".d-none").addClass("d-none");

    $("#selectTeamCreateOkr").parent().css("box-shadow", "none");
    $(".modal_show_team").attr("timeIdKr", timeIdKr);
    $(".modal_show_team #team_selected").val(targetSelect);

    if (e.find("option:selected").text() == "None")
      $("#modalTemporarySearchTeam li[teamId='0']").find("#icon-checked").removeClass("d-none");

    $("#modalTemporarySearchTeam li[teamId='" + targetSelect + "']")
      .find("#icon-checked")
      .removeClass("d-none");
  } catch (err) {
    console.log(err);
  }
};

window.onChangeTemporarySearchTeams = debounce(function (e) {
  var team_id = e.parents(".modal").find("#team_selected").val();
  $.ajax({
    type: "GET",
    url: "/pages/objectives/search_temporary_team",
    data: {
      search: e.val(),
      team_selected: team_id,
      type_modal: "temporary",
      quarter_input: $("#objective_period_quarter").val(),
    },
  });
}, 500);

window.onChangeImmediateSearchTeams = debounce(function (e) {
  var team_id = e.parents(".modal").find("#team_selected").val();
  $.ajax({
    type: "GET",
    url: "/pages/objectives/search_immediate_team",
    data: {
      search: e.val(),
      team_selected: team_id,
      type_modal: "immediate",
    },
  });
}, 500);

window.setTeamKrForm = (e) => {
  let nested_id, tartgetNestedForm, new_team_val;
  $(".team-kr-incompany").children("option").show();

  nested_id = e.parents(".modal_show_team").attr("timeidkr");
  tartgetNestedForm = $(".nested-fields[data-index='" + nested_id + "']");

  if (e.attr("teamId") == "0") {
    tartgetNestedForm.find(".team-kr-incompany").val("");
    $(tartgetNestedForm.find("option")[0]).text("None");
  } else {
    tartgetNestedForm.find(".team-kr-incompany").val(e.attr("teamId"));
  }
  e.find("#icon-checked").removeClass("d-none");
  $("#modalTemporarySearchTeam").modal("hide");

  new_team_val = tartgetNestedForm.find(".team-kr-incompany").val();
  if (e.attr("teamId") == "0") {
    reloadMember(new_team_val, "company", nested_id);
  } else {
    reloadMember(new_team_val, "team", nested_id);
  }
};

window.reloadMember = (team_id, level, nested_id = "") => {
  $.ajax({
    url: "/common/reload_member_of_team",
    type: "GET",
    data: { team_id: team_id, level: level, nested_id: nested_id },
  });
};

window.decode_string_to_json_obj = () => {
  let objective = localStorage.getItem("objective");
  if (objective != null && objective != "") {
    let childrens_attributes = {};
    objective = JSON.parse(objective);
    let childrens = JSON.parse(objective["childrens_attributes"]);
    childrens.forEach((e, index) => {
      childrens_attributes[index] = JSON.parse(e);
    });
    objective["childrens_attributes"] = childrens_attributes;
    console.log(objective, "js file");
  }
  return objective;
};
