import 'select2'

// $(document).ready(function() {
//   $(".js-source-select2").select2({
//     theme: "bootstrap"
//   });

//   $(".js-source-select2-multiple").select2({
//     tags: true,
//     tokenSeparators: [',', ' ']
//   })
// });
