window.closeAccount = () => {
  if ($("#type_delete").val() == "DELETE") {
    $("#closeAccount").modal("hide");
    $("#notifyCloseAccount").modal("show");
    setTimeout(function () {
      $.ajax({
        type: "PUT",
        url: `/pages/admin/company/close_account`,
        success: function (data) {},
      });
    }, 3000);
  }
};
