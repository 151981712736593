window.totalGoal = function (arr) {
  var point = 0;
  $.each(arr, function (index, value) {
    point += calculationGoal(value);
  });
  return point;
};

window.calculationGoal = function (e) {
  if (e == "increase_by_2x") return 2;
  if (e == "increase_by_3x") return 3;
  if (e == "increase_by_4x") return 4;
  if (e == "decrease_by_2x") return 0.5;
  if (e == "decrease_by_3x") return 0.33;
  if (e == "decrease_by_4x") return 0.25;
  else return 1;
};

window.parsePoint = function (e) {
  return (e % 1 == 0 && e) || parseFloat(e.toFixed(2));
};

window.percentGoal = function (e, target) {
  var per = (e / target) * 100;
  return parsePoint(per);
};

window.onSubmitGoal = function (e) {
  var targetForm = $(e).closest("#modalSetupGoal").find("#contentSetupGoel");
  var measurementValue = $(targetForm).find("#objective_childrens_name").val();
  var startValue = $(targetForm).find("#objective_childrens_start_point").val();
  var endValue = $(targetForm).find("#objective_childrens_target_point").val();
  var weightageValue = $(targetForm)
    .find("#objective_childrens_weightage option[selected='selected']")
    .val();

  var errorFrom = [];
  var errorMeasurement = [];
  var errorStartValue = [];
  var errorEndValue = [];
  var errorWeightageValue = [];

  var $kr_id;

  if (!measurementValue.replace(/ /g, "")) {
    errorFrom.push("Please fill in your goal's unit measurement.");
    errorMeasurement.push("Please fill in your goal's unit measurement.");
  } else if (measurementValue != "" && measurementValue.length > 30) {
    errorFrom.push("Description must not exceed 30 characters.");
    errorMeasurement.push("Description must not exceed 30 characters.");
  }

  if (measurementValue == "default_percent") {
    errorFrom.push("Goal's Unit Measurement can't use value 'default_percent'.");
    errorMeasurement.push("Goal's Unit Measuremen can't use value 'default_percent'");
  }

  if (!startValue.replace(/ /g, "")) {
    errorFrom.push("Please fill in your goal's starting value.");
    errorStartValue.push("Please fill in your goal's starting value.");
  }

  if (!endValue.replace(/ /g, "")) {
    errorFrom.push("Please fill in your goal's ending value.");
    errorEndValue.push("Please fill in your goal's ending value.");
  }

  if (startValue != "" && !Number.isInteger(parseFloat(startValue))) {
    errorFrom.push("Please use whole/round numbers only.");
    errorStartValue.push("Please use whole/round numbers only.");
  }

  if (
    startValue != 0 &&
    parseInt(startValue) > 1000000000000 &&
    Number.isInteger(parseFloat(startValue))
  ) {
    errorFrom.push("Start Value can't greater than 100,0000,000,000.");
    errorStartValue.push("Start Value can't greater than 100,0000,000,000.");
  }

  if (parseInt(startValue) < 0) {
    errorFrom.push("Start Value can't lower than 0.");
    errorStartValue.push("Start Value can't lower than 0.");
  }

  if (parseInt(endValue) < 0) {
    errorFrom.push("End Value can't lower than 0.");
    errorEndValue.push("End Value can't lower than 0.");
  }

  if (goalValueCondition(startValue, endValue)) {
    errorFrom.push("Start/end values cannot be the same.");
    errorStartValue.push("Start/end values cannot be the same.");
    errorEndValue.push("Start/end values cannot be the same.");
  }

  if (endValue != "" && !Number.isInteger(parseFloat(endValue))) {
    errorFrom.push("Please use whole/round numbers only.");
    errorEndValue.push("Please use whole/round numbers only.");
  }

  if (
    endValue != 0 &&
    parseInt(endValue) > 1000000000000 &&
    Number.isInteger(parseFloat(endValue))
  ) {
    errorFrom.push("End Value can't greater than 100,0000,000,000.");
    errorEndValue.push("End Value can't greater than 100,0000,000,000.");
  }

  if (!weightageValue.replace(/ /g, "")) {
    errorFrom.push("Weightage can't be blank.");
    errorWeightageValue.push("Weightage can't be blank");
  }

  if (errorMeasurement.length > 0) {
    $(targetForm).find("#objective_childrens_name").addClass("is-invalid");
    $(targetForm).find("#error_objective_childrens_name").removeClass("d-none");
    $(targetForm).find("#error_objective_childrens_name").text(errorMeasurement.join(", "));
  } else {
    $(targetForm).find("#objective_childrens_name").removeClass("is-invalid");
    $(targetForm).find("#error_objective_childrens_name").addClass("d-none");
    $(targetForm).find("#error_objective_childrens_name").text("");
  }

  if (errorStartValue.length > 0) {
    $(targetForm).find("#objective_childrens_start_point").addClass("is-invalid");
    $(targetForm).find("#error_objective_childrens_start_point").removeClass("d-none");
    $(targetForm).find("#error_objective_childrens_start_point").text(errorStartValue.join(", "));
  } else {
    $(targetForm).find("#objective_childrens_start_point").removeClass("is-invalid");
    $(targetForm).find("#error_objective_childrens_start_point").addClass("d-none");
    $(targetForm).find("#error_objective_childrens_start_point").text("");
  }

  if (errorEndValue.length > 0) {
    $(targetForm).find("#objective_childrens_target_point").addClass("is-invalid");
    $(targetForm).find("#error_objective_childrens_target_point").removeClass("d-none");
    $(targetForm).find("#error_objective_childrens_target_point").text(errorEndValue.join(", "));
  } else {
    $(targetForm).find("#objective_childrens_target_point").removeClass("is-invalid");
    $(targetForm).find("#error_objective_childrens_target_point").addClass("d-none");
    $(targetForm).find("#error_objective_childrens_target_point").text("");
  }

  if (errorWeightageValue.length > 0) {
    $(targetForm).find("#objective_childrens_weightage").addClass("is-invalid");
    $(targetForm).find("#error_objective_childrens_weightage").removeClass("d-none");
    $(targetForm).find("#error_objective_childrens_weightage").text(errorWeightageValue.join(", "));
  } else {
    $(targetForm).find("#objective_childrens_weightage").removeClass("is-invalid");
    $(targetForm).find("#error_objective_childrens_weightage").addClass("d-none");
    $(targetForm).find("#error_objective_childrens_weightage").text("");
  }

  if (errorFrom.length == 0) {
    var keyIndex = $("#modalSetupGoal").attr("data-index");
    if (keyIndex) {
      var tartgetNestedForm = $(".nested-fields[data-index='" + keyIndex + "']");
      $(tartgetNestedForm).find("#dataKrGoal #goalMetric").val(measurementValue);
      $(tartgetNestedForm).find("#dataKrGoal #goalStartPoint").val(startValue);
      $(tartgetNestedForm).find("#dataKrGoal #goalTargetPoint").val(endValue);
      $(tartgetNestedForm).find("#dataKrGoal #goalWeightage").val(weightageValue);
      $(tartgetNestedForm)
        .find("#dataKrGoal #goalWeightage option[selected='selected']")
        .attr("selected", false);
      $(tartgetNestedForm)
        .find("#dataKrGoal #goalWeightage option[value='" + weightageValue + "']")
        .attr("selected", true);

      $(tartgetNestedForm).find("#input-range .goal-unit-m").text(measurementValue);

      $(tartgetNestedForm).find("#input-range input").attr("start", startValue);
      $(tartgetNestedForm).find("#input-range input").attr("end", endValue);
      $(tartgetNestedForm).find("#input-range input").attr("metric", measurementValue);

      // add values to localstorage
      var values = { start: startValue, end: endValue, metric: measurementValue };

      sessionStorage.setItem("setupGoal", JSON.stringify(values));

      if (parseInt(startValue) > parseInt(endValue)) {
        var revert = true;
        $(tartgetNestedForm).find("input[type=range]").attr("min", endValue);
        $(tartgetNestedForm).find("input[type=range]").attr("max", startValue);
      } else {
        revert = false;
        $(tartgetNestedForm).find("input[type=range]").attr("min", startValue);
        $(tartgetNestedForm).find("input[type=range]").attr("max", endValue);
      }

      $(tartgetNestedForm).find("input[type=range]").attr("revert", revert);

      if ($(tartgetNestedForm).find(`input[placeholder="Type key result here"]`).length > 0) {
        $(tartgetNestedForm).find(`input[metric="${measurementValue}"]`).val(0);
        $(tartgetNestedForm).find(`input[metric="${measurementValue}"]`).css("backgroundSize", "0");
        $(tartgetNestedForm)
          .find(`input[metric="${measurementValue}"]`)
          .removeClass()
          .addClass("gray");
      } else if (
        $(tartgetNestedForm).find(`input[placeholder="Type your key result here"]`).length > 0
      ) {
        $(tartgetNestedForm)
          .find(".createokrs-percent")
          .text(startValue + " " + measurementValue);
        $(tartgetNestedForm).find(`input[metric="${measurementValue}"]`).val(0);
        $(tartgetNestedForm).find(`input[metric="${measurementValue}"]`).css("backgroundSize", "0");
        $(tartgetNestedForm)
          .find(`input[metric="${measurementValue}"]`)
          .removeClass()
          .addClass("gray");
      }
    }

    $("#modalSetupGoal").modal("hide");
    $(tartgetNestedForm).find(".kr-form-progress").css("bottom", "unset");
    $(tartgetNestedForm)
      .find(".spacing-value")
      .removeClass("color-red color-yellow color-green-100");
    $(tartgetNestedForm).find("#fake_value").addClass("d-none");
    $(tartgetNestedForm).find("#real_value").addClass("d-none");
  }
  var sessionLeftSideBar = null;
  if (!!sessionStorage.getItem("open")) {
    sessionLeftSideBar = sessionStorage.getItem("open");
  }
  sessionStorage.clear();
  sessionStorage.setItem("open", sessionLeftSideBar);

  if (!Number.isInteger($("#modalSetupGoal").data("index")))
    $kr_id = $("#modalSetupGoal").data("index").split("okr-").filter(String)[0];
  else $kr_id = false;

  var $big_progress_bar = $(".big_progress_bar").length;
  if (
    $(".nested-fields[data-index='" + keyIndex + "']")
      .find(".objective_childrens_name input")
      .attr("placeholder") == "Type key result here"
  ) {
    $(".nested-fields[data-index='" + keyIndex + "']")
      .find(".spacing-value")
      .text(`${startValue} ${measurementValue}`);
  } else {
    if (errorFrom.length == 0 && errorStartValue.length == 0 && errorEndValue.length == 0) {
      if ($kr_id || $big_progress_bar > 0) {
        if ($kr_id == undefined) {
          $kr_id = window.location.pathname.split("/").slice(-1)[0];
        }
        $.ajax({
          url: `/pages/objectives/${$kr_id}/update_goal_kr`,
          method: "PUT",
          data: {
            metric: measurementValue,
            start_point: startValue,
            target_point: endValue,
            weightage: weightageValue,
          },
          success: function (data) {
            localStorage.setItem("collapse_okr", JSON.stringify([data["parent_id"]] || []));
            localStorage.setItem("flash_message", "Unit measurement updated!");
            if (data["reload"]) location.reload();
          },
        });
      } else {
        afterUpdateBigProgressBar("Unit measurement updated!");
      }
    }
  }

  var selectQuarter = debounce(function (quarterF, yearF, element) {
    let selector = element.find("input[type=range]");
    var url = window.location.href.split("/");
    if (url.includes("objectives")) {
      selector
        .removeClass()
        .addClass("bg-image-green-100 bg-image-gray-400 bg-gray-400-opacity gray");
    }
  }, 0);
  var date = new Date();
  var month = date.getMonth() + 1;
  var quarter = Math.ceil(month / 3);
  var year = date.getFullYear();
  selectQuarter(quarter, year, $(tartgetNestedForm));
};

window.parseWeightageText = function (percent) {
  return "This KR makes up " + Math.round(parseInt(percent)) + "% of the Objective";
};

window.reloadWeightageUI = function () {
  $("#objective_childrens_weightage option").each(function () {
    var cPoint = parsePoint($(this).data("point"));
    var sPoint = parsePoint($("#objective_childrens_weightage").data("point"));
    var calPercent = percentGoal(cPoint, cPoint + sPoint);
    $(this).attr("data-percent", calPercent);
  });

  var checkedPercent = $("#objective_childrens_weightage option[selected='selected']").data(
    "percent",
  );
  $("#txtGoals").text(parseWeightageText(checkedPercent));
  $("#progressGoals").css("width", checkedPercent + "%");
};

window.goalValueCondition = (startValue, endValue) => {
  return (
    Number.isInteger(parseFloat(startValue)) &&
    Number.isInteger(parseFloat(endValue)) &&
    parseInt(endValue) == parseInt(startValue)
  );
};
