window.loadLines = async () => {
  const types = [
    "historyCompanyOkrs",
    "historyAllTeams",
    "historyMyTeams",
    "historyMyOkrs",
    "Department",
    "Team",
    "Account",
  ];
  for (let type of types) {
    await LoadTypeLines(type);
  }
};

window.LoadTypeLines = (type) => {
  if (!!sessionStorage.getItem(type)) {
    let arr = JSON.parse(sessionStorage.getItem(type));
    arr.forEach((hashEle) => {
      addDatasetReload(hashEle.id, hashEle.name, null, type);
    });
  }
};

window.removeLine = (type, id, name) => {
  let arr, new_arr, normalizeType;

  normalizeType = ["departments", "teams", "accounts"].includes(type)
    ? (type.charAt(0).toUpperCase() + type.slice(1)).slice(0, -1)
    : type;
  arr = JSON.parse(sessionStorage.getItem(normalizeType));

  if (["departments", "teams", "accounts"].includes(type)) {
    new_arr = arr.filter((x) => JSON.stringify(x) !== JSON.stringify({ id: id, name: name }));
  } else {
    new_arr = arr.filter((x) => JSON.stringify(x) !== JSON.stringify({ name: name }));
  }
  sessionStorage.setItem(normalizeType, JSON.stringify(new_arr));
};

window.checkCheckBox = (type) => {
  if (["historyCompanyOkrs", "historyAllTeams", "historyMyTeams", "historyMyOkrs"].includes(type)) {
    $(`#${type}CheckBox`).prop("checked", true);
  }
};
