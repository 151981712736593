import { Controller } from "@hotwired/stimulus";
import action_cable, { consumer, custom_consumer } from "../channels/consumer.js";

// Connects to data-controller="tuf_alert"
export default class extends Controller {
  static targets = [
    "message_show",
    "message_input",
    "button",
    "channel_name",
    "channel_id",
    "the_consumer",
    "channel_name_1",
    "channel_id_1",
    "the_consumer_1",
  ];

  connect() {
    console.log("TufAlert stimulus connected!");
  }

  get message_show() {
    return this.message_showTarget;
  }

  get messages_show() {
    return this.message_showTargets;
  }

  get message_input() {
    return this.message_inputTarget;
  }

  get button() {
    return this.buttonTarget;
  }

  get channel_name() {
    return this.channel_nameTarget;
  }

  get channel_id() {
    return this.channel_idTarget;
  }

  get channel() {
    return `${this.channel_name.value}_${this.channel_id.value}`;
  }

  get the_consumer() {
    return this.the_consumerTarget.value;
  }

  get connection_info() {
    // aka identifier in action_cable/consumer context
    return {
      channel: "TufWhisperChannel",
      channel_name: this.channel_name.value,
      channel_id: this.channel_id.value,
    };
  }

  get channel_info() {
    return JSON.stringify(this.connection_info);
  }

  get callbacks_info() {
    let self = this;
    return {
      connected() {
        // Called when the subscription is ready for use on the server
        console.log("Connected to the room channel: " + self.channel);
      },
      disconnected() {
        // Called when the subscription has been terminated by the server
        console.log("Disconnected from the room channel ...");
      },
      received(data) {
        // Called when there's incoming data on the websocket for this channel
        console.log(`Received ${data} from the room channel ...`);
      },
    };
  }

  find_channel(identifier) {
    return eval(this.the_consumer).subscriptions.findAll(identifier)[0];
  }

  copy(e) {
    navigator.clipboard.writeText(this.message_input.value);
    $(this.messages_show).html(this.message_input.value);
  }

  listen(e) {
    var channel = this.find_channel(this.channel_info);
    if (channel) {
      console.log("Channel already subscribed!");
    } else {
      eval(this.the_consumer).subscriptions.create(this.connection_info, this.callbacks_info);
    }
  }

  unsubscribe(e) {
    var channel = this.find_channel(this.channel_info);
    if (channel) {
      channel.unsubscribe();
      console.log("Unsubscribed from channel!", channel);
    }
  }

  /*==========================*/
  get channel_name_1() {
    return this.channel_name_1Target;
  }

  get channel_id_1() {
    return this.channel_id_1Target;
  }

  get the_consumer_1() {
    return this.the_consumer_1Target;
  }

  update_param(e) {
    const element = e.target;
    // element.setAttribute(`data-tuf_alert-${element.name}-param`, element.value);
    eval(`this.${element.name}`).setAttribute(
      `data-tuf_alert-${element.name}-param`,
      eval(`this.${element.name}.value`),
    );
  }

  chingchong(e) {}

  dingdong(e) {}
}
